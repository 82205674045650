<template>
  <div class="tagShowDiv">
    <!-- 分类 -->
    <tag-item
      :data="platformClassifyData"
      classify="分类"
      :reset="modelValue"
      v-model="tags.categories"
    />
    <!-- 级别，榜单1.0没有级别 -->
    <tag-item
      v-show="listVersion"
      :data="tierData"
      classify="级别"
      :reset="modelValue"
      v-model="tags.tiers"
    />
    <!-- 标签 榜单1.0没有标签-->
    <tag-item
      v-show="listVersion"
      :data="labelData"
      classify="标签"
      :reset="modelValue"
      v-model="tags.labels"
    />
    <!-- 其他筛选 -->
    <div v-show="otherFilterList.length">
      <p class="title">其他筛选</p>
      <filterItem
        v-for="(item, index) in otherFilterList"
        :key="'otherFilter' + index"
        :data="item"
        @handleValue="filterObjSet"
        @deleteFilter="deleteFilterData"
        :resetFlag="resetFilterItem"
      >
      </filterItem>
    </div>
    <!-- 数据筛选 -->
    <div>
      <p class="title">数据筛选</p>
      <filterItem
        v-for="(item, index) in dataFilterList"
        :key="'dataFilter' + index"
        :data="item"
        @handleValue="filterObjSet"
        @deleteFilter="deleteFilterData"
        :resetFlag="resetFilterItem"
        :clearInput="clearInput"
      >
      </filterItem>
    </div>

    <!-- 合作信息筛选 -->
    <div
      v-show="isShowCoopInfoFilter"
      style="border-bottom: 1px solid rgb(220, 223, 230)"
    >
      <p class="title">己方合作信息筛选</p>
      <filterItem
        v-for="(item, index) in infoDataFilterList"
        :key="'coopCount' + index"
        :data="item"
        @handleValue="filterObjSet"
        @deleteFilter="deleteFilterData"
        :resetFlag="resetFilterItem"
        :clearInput="clearInput"
      >
      </filterItem>
      <el-form :inline="true" label-width="120px">
        <el-form-item label="己方合作品牌：">
          <el-input
            size="mini"
            v-model="coopBrand"
            placeholder="请输入合作品牌"
          ></el-input>
        </el-form-item>
        <el-form-item style="margin-left: 20px" label="合作类型：">
          <el-select
            size="mini"
            multiple
            v-model="coopCampaignType"
            placeholder="请选择合作类型"
          >
            <el-option
              :key="item.value"
              v-for="item in coopTypeOptions"
              :value="item.value"
              :label="item.label"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <p class="title">人设筛选</p>
      <el-form :inline="true" label-width="120px" @submit.native.prevent>
        <el-form-item label="人设筛选：">
          <el-input
            v-model="filterObj.gptSearchWord"
            size="mini"
            placeholder="请输入人设内容"
          />
        </el-form-item>
      </el-form>
    </div>

    <!-- 按钮组 （确定/重置）-->
    <div class="btnDiv text-right mr-30 mt-10">
      <el-button
        size="mini"
        type="primary"
        style="vertical-align: top"
        @click="submitData"
      >
        确定
      </el-button>
      <el-button
        size="mini"
        type="primary"
        style="vertical-align: top"
        @click="resetClick"
      >
        重置
      </el-button>
    </div>
  </div>
</template>

<script>
import tagItem from "./tagItem";
import filterItem from "./filterItem";
import { getCampaignType } from "@/api/campaign";
import { mapGetters } from "vuex";

export default {
  name: "tag-show",
  data() {
    return {
      tags: {
        categories: ["All"], // 标签
        tiers: ["All"], // tier，榜单1.0没有tier
        labels: ["All"],
        // filter: {}, // 其他筛选,
      },
      filterObj: {
        gptSearchWord: "",
      },
      filterTags: [],
      resetFilterItem: false,
      clearInput: false,
      // 固定数据
      // 榜单2。0其他筛选
      v2OtherFilter: [
        {
          label: "粉丝数",
          value: "kolFans",
          showPercent: false,
        },
        {
          label: "近4周触达UV",
          value: "activeViewerNum",
          showPercent: false,
        },
        {
          label: "周作品数量",
          value: "postCount",
          showPercent: false,
        },
        {
          label: "单条阅读UV",
          value: "postRead",
          showPercent: false,
        },
        {
          label: "单条互动",
          value: "postEngagement",
          showPercent: false,
        },
        {
          label: "单条阅读趋势",
          value: "postTrafficTrend",
          showPercent: false,
        },
        {
          label: "粉丝活跃度指数",
          value: "fansActiveIndex",
          showPercent: false,
        },
        // {
        //   label: "有效流量指数",
        //   value: "effectiveTrafficIndex",
        //   showPercent: false,
        // },
        {
          label: "作品水量指数",
          value: "waterIndex",
          showPercent: false,
        },
        {
          label: "内容质量指数",
          value: "contentQualityIndex",
          showPercent: false,
        },
        {
          label: "TA覆盖度",
          value: "taPct",
          showPercent: true,
        },
        // {
        //   label: "TA浓度",
        //   value: "taRatio",
        //   showPercent: true,
        // },
        // {
        //   label: "TA TGI",
        //   value: "taTgi",
        //   showPercent: false,
        // },
      ],
      // 榜单1。0其他筛选
      wechatOtherFilter: [
        {
          label: "月总阅读UV",
          value: "muv",
          showPercent: false,
          filterFiled: {
            muvBelow: 0,
            muvAbove: 0,
          },
        },
        {
          label: "首推阅读",
          value: "viewMonthAvg1st",
          showPercent: false,
        },
        {
          label: "月单篇互动数",
          value: "engagementMonthAvg",
          showPercent: false,
        },
        {
          label: "月文章数量",
          value: "postCount",
          showPercent: false,
        },
        {
          label: "半年刷水推文占比",
          value: "fakePostPct",
          showPercent: true,
        },
        {
          label: "刷水文章水量",
          value: "waterValueAvg",
          showPercent: true,
        },
        {
          label: "TA%",
          value: "tarPct",
          showPercent: true,
        },
        {
          label: "TA TGI",
          value: "tarTgi",
          showPercent: false,
        },
      ],
      weiboOtherFilter: [
        {
          label: "月总互动",
          value: "mact",
          showPercent: false,
        },
        {
          label: "月单篇互动数",
          value: "postAct",
          showPercent: false,
        },
        {
          label: "月文章数量",
          value: "postCount",
          showPercent: false,
        },
        {
          label: "粉丝数",
          value: "kolFans",
          showPercent: false,
        },
        {
          label: "假粉率",
          value: "fansWater",
          showPercent: true,
        },
        {
          label: "TA%",
          value: "tarPct",
          showPercent: true,
        },
        {
          label: "TA TGI",
          value: "tarTgi",
          showPercent: false,
        },
      ],
      douyinOtherFilter: [
        {
          label: "月总订阅UV",
          value: "muvFans",
          showPercent: false,
        },
        {
          label: "月总曝光",
          value: "muv",
          showPercent: false,
        },
        {
          label: "月单篇互动数",
          value: "postAct",
          showPercent: false,
        },
        {
          label: "月文章数量",
          value: "postCount",
          showPercent: false,
        },
        {
          label: "月总互动",
          value: "mact",
          showPercent: false,
        },
        {
          label: "月单篇曝光UV",
          value: "postExposure",
          showPercent: false,
        },
        {
          label: "粉丝数",
          value: "kolFans",
          showPercent: false,
        },
        {
          label: "活跃粉丝",
          value: "fansActivePct",
          showPercent: true,
        },
        {
          label: "TA%",
          value: "tarPct",
          showPercent: true,
        },
        {
          label: "TA TGI",
          value: "tarTgi",
          showPercent: false,
        },
      ],
      xiaohongshuOtherFilter: [
        {
          label: "阅读月UV",
          value: "muv",
          showPercent: false,
        },
        {
          label: "月单篇阅读UV",
          value: "postUv",
          showPercent: false,
        },
        {
          label: "月单篇互动数",
          value: "postAct",
          showPercent: false,
        },
        {
          label: "月文章数量",
          value: "postCount",
          showPercent: false,
        },
        {
          label: "粉丝数",
          value: "kolFans",
          showPercent: false,
        },
        {
          label: "活跃粉丝",
          value: "fansActivePct",
          showPercent: true,
        },
        {
          label: "半年刷水推文占比",
          value: "fakePostPct",
          showPercent: true,
        },
        {
          label: "互动水量",
          value: "waterValueAvg",
          showPercent: true,
        },
        {
          label: "TA%",
          value: "tarPct",
          showPercent: true,
        },
        {
          label: "TA TGI",
          value: "tarTgi",
          showPercent: false,
        },
      ],
      bilibiliOtherFilter: [
        {
          label: "月总阅读UV",
          value: "muv",
          showPercent: false,
        },
        {
          label: "单篇浏览UV",
          value: "monthAvgUv",
          showPercent: false,
        },
        {
          label: "月单篇互动数",
          value: "monthAvgAct",
          showPercent: false,
        },
        {
          label: "月文章数量",
          value: "postCount",
          showPercent: false,
        },
        {
          label: "月总播放",
          value: "monthPlaySum",
          showPercent: false,
        },
        {
          label: "粉丝数",
          value: "kolFans",
          showPercent: false,
        },
        {
          label: "TA%",
          value: "tarPct",
          showPercent: true,
        },
        {
          label: "TA TGI",
          value: "tarTgi",
          showPercent: false,
        },
      ],
      kuaishouOtherFilter: [
        {
          label: "月浏览UV",
          value: "muv",
          showPercent: false,
        },
        {
          label: "月总互动",
          value: "mact",
          showPercent: false,
        },
        {
          label: "月单篇曝光",
          value: "postExposure",
          showPercent: false,
        },
        {
          label: "视频互动",
          value: "postAct",
          showPercent: false,
        },
        {
          label: "月发布篇数",
          value: "postCount",
          showPercent: false,
        },
        {
          label: "粉丝数",
          value: "kolFans",
          showPercent: false,
        },
        {
          label: "TA%",
          value: "tarPct",
          showPercent: true,
        },
        {
          label: "TA TGI",
          value: "tarTgi",
          showPercent: false,
        },
      ],
      // 数据筛选
      wechatDataFilter: [
        {
          label: "ER",
          value: "engagementRate",
          showPercent: true,
        },
        {
          label: this.listVersion === 1 ? "CPUV(一推)" : "CPUV(一推)",
          value: "cpuv",
          showPercent: false,
        },
        {
          label: this.listVersion === 1 ? "CPUV(二推)" : "CPUV(二推)",
          value: "cpuvSecond",
          showPercent: false,
        },
        {
          label: "CPE(一推)",
          value: "cpe",
          showPercent: false,
        },
        {
          label: "CPE(二推)",
          value: "cpeSecond",
          showPercent: false,
        },
        {
          label: "价格(一推)",
          value: "price",
          showPercent: false,
        },
        {
          label: "价格(二推)",
          value: "priceSecond",
          showPercent: false,
        },
        {
          label: "CPTA(一推)",
          value: "cpta",
          showPercent: false,
        },
        {
          label: "CPTA(二推)",
          value: "cptaSecond",
          showPercent: false,
        },
      ],
      douyinDataFilter: [
        {
          label: "ER",
          value: "engagementRate",
          showPercent: true,
        },
        {
          label:
            this.listVersion === 1
              ? "CPUV（视频20-60s）"
              : "CPUV（视频20-60s）",
          value: "cpuv",
          showPercent: false,
        },
        {
          label: this.listVersion === 1 ? "CPUV(视频1-20s)" : "CPUV(视频1-20s)",
          value: "cpuvSecond",
          showPercent: false,
        },
        {
          label: "CPE（视频20-60s）",
          value: "cpe",
          showPercent: false,
        },
        {
          label: "CPE(视频1-20s)",
          value: "cpeSecond",
          showPercent: false,
        },
        {
          label: "价格（视频20-60s）",
          value: "price",
          showPercent: false,
        },
        {
          label: "价格(视频1-20s)",
          value: "priceSecond",
          showPercent: false,
        },
        {
          label: "CPTA（视频20-60s",
          value: "cpta",
          showPercent: false,
        },
        {
          label: "CPTA(视频1-20s)",
          value: "cptaSecond",
          showPercent: false,
        },
      ],
      xiaohongshuDataFilter: [
        {
          label: "ER",
          value: "engagementRate",
          showPercent: true,
        },
        {
          label: this.listVersion === 1 ? "CPUV（图文笔记）" : "CPUV(图文笔记)",
          value: "cpuv",
          showPercent: false,
        },
        {
          label: this.listVersion === 1 ? "CPUV(视频笔记)" : "CPUV(视频笔记)",
          value: "cpuvSecond",
          showPercent: false,
        },
        {
          label: "CPE（图文笔记）",
          value: "cpe",
          showPercent: false,
        },
        {
          label: "CPE(视频笔记)",
          value: "cpeSecond",
          showPercent: false,
        },
        {
          label: "价格(图文笔记)",
          value: "price",
          showPercent: false,
        },
        {
          label: "价格(视频笔记)",
          value: "priceSecond",
          showPercent: false,
        },
        {
          label: "CPTA（图文笔记）",
          value: "cpta",
          showPercent: false,
        },
        {
          label: "CPTA(视频笔记)",
          value: "cptaSecond",
          showPercent: false,
        },
      ],
      bilibiliDataFilter: [
        {
          label: "ER",
          value: "engagementRate",
          showPercent: true,
        },
        {
          label: this.listVersion === 1 ? "CPUV(视频定制)" : "CPUV(视频定制)",
          value: "cpuv",
          showPercent: false,
        },
        {
          label: this.listVersion === 1 ? "CPUV(视频植入)" : "CPUV(视频植入)",
          value: "cpuvSecond",
          showPercent: false,
        },
        {
          label: "CPE(视频定制)",
          value: "cpe",
          showPercent: false,
        },
        {
          label: "CPE(视频植入)",
          value: "cpeSecond",
          showPercent: false,
        },
        {
          label: "价格(视频定制)",
          value: "price",
          showPercent: false,
        },
        {
          label: "价格(视频植入)",
          value: "priceSecond",
          showPercent: false,
        },
        {
          label: "CPTA(视频定制)",
          value: "cpta",
          showPercent: false,
        },
        {
          label: "CPTA(视频植入)",
          value: "cptaSecond",
          showPercent: false,
        },
      ],
      kuaishouDataFilter: [
        {
          label: "ER",
          value: "engagementRate",
          showPercent: true,
        },
        {
          label: this.listVersion === 1 ? "CPUV(原创视频)" : "CPUV(原创视频)",
          value: "cpuv",
          showPercent: false,
        },
        {
          label: "CPE(原创视频)",
          value: "cpe",
          showPercent: false,
        },
        {
          label: "价格(原创视频)",
          value: "price",
          showPercent: false,
        },
        {
          label: "CPTA(原创视频)",
          value: "cpta",
          showPercent: false,
        },
      ],
      campaignInfoDataFilter: [
        {
          label: "ER",
          value: "engagementRate",
          showPercent: true,
        },
        {
          label: "CPI",
          value: "cpm",
          showPercent: false,
        },
        {
          label: "CPE",
          value: "cpe",
          showPercent: false,
        },
        {
          label: "参考价格",
          value: "price",
          showPercent: false,
        },
        {
          label: "CPTA",
          value: "cpta",
          showPercent: false,
        },
      ],
      coopBrand: "",
      coopCampaignType: [],
      coopTypeOptions: [],
    };
  },
  props: {
    labelData: {
      type: Array,
      default() {
        return [];
      },
    },
    // v-model 清空数据
    modelValue: {
      type: Boolean,
      default: false,
    },
    isShowCoopInfoFilter: {
      type: Boolean,
      default: true,
    },
  },
  mounted() {
    this.getCampaignType();
  },
  computed: {
    ...mapGetters(["siteValue", "colSiteValue", "listVersion", "classifyData"]),
    chosenSite() {
      return this.$route.name === "RankingListV2"
        ? this.siteValue
        : this.colSiteValue;
    },
    platformClassifyData() {
      return this.classifyData[this.chosenSite];
    },
    tierData() {
      if (this.listVersion === 1 && this.$route.name !== "CampaignInfo") {
        return [
          {
            name: "Tier1",
            tip: "",
          },
          {
            name: "Tier2",
            tip: "",
          },
          {
            name: "Tier3",
            tip: "",
          },
          {
            name: "KOC",
            tip: "",
          },
        ];
      } else {
        return [];
      }
    },
    otherFilterList() {
      if (this.listVersion === 1 && this.$route.name !== "CampaignInfo") {
        return this.v2OtherFilter;
      } else if (this.$route.name !== "CampaignInfo") {
        const otherFilter = this.chosenSite + "OtherFilter";
        return this[otherFilter];
      } else {
        return [];
      }
    },
    weiboDataFilter() {
      return this.listVersion === 1
        ? [
            {
              label: "ER",
              value: "engagementRate",
              showPercent: true,
            },
            {
              label: "CPUV（直发）",
              value: "cpuv",
              showPercent: false,
            },
            {
              label: "CPUV（转发）",
              value: "cpuvSecond",
              showPercent: false,
            },
            {
              label: "CPE（直发）",
              value: "cpe",
              showPercent: false,
            },
            {
              label: "CPE（转发）",
              value: "cpeSecond",
              showPercent: false,
            },
            {
              label: "价格（直发）",
              value: "price",
              showPercent: false,
            },
            {
              label: "价格（转发）",
              value: "priceSecond",
              showPercent: false,
            },
            {
              label: "CPTA（直发）",
              value: "cpta",
              showPercent: false,
            },
            {
              label: "CPTA（转发）",
              value: "cptaSecond",
              showPercent: false,
            },
          ]
        : [
            {
              label: "价格（直发）",
              value: "price",
              showPercent: false,
            },
            {
              label: "价格（转发）",
              value: "priceSecond",
              showPercent: false,
            },
            {
              label: "CPE（直发）",
              value: "cpe",
              showPercent: false,
            },
            {
              label: "CPE（转发）",
              value: "cpeSecond",
              showPercent: false,
            },
          ];
    },
    infoDataFilterList() {
      return [
        {
          label: "合作次数：",
          value: "coopCount",
          showPercent: false,
        },
      ];
    },
    dataFilterList() {
      const dataFilter =
        this.$route.name === "CampaignInfo"
          ? "campaignInfoDataFilter"
          : this.chosenSite + "DataFilter";
      return this[dataFilter];
    },
  },
  methods: {
    async getCampaignType() {
      let res = await getCampaignType();
      if (res.code === 0) {
        let temp = [];
        res.data
          .filter((v) => v !== "")
          .forEach((v) => {
            temp.push({
              label: v,
              value: v,
            });
          });
        this.coopTypeOptions = temp;
      }
    },
    delCategoryData() {
      this.tags.categories = ["All"];
    },
    delTierData() {
      this.tags.tiers = ["All"];
    },
    delLabelData() {
      this.tags.labels = ["All"];
    },
    delCoopBrand() {
      this.coopBrand = "";
      this.submitData();
    },
    delCampaignTypes() {
      this.coopCampaignType = [];
      this.submitData();
    },
    deleteFilterData(filterKeys) {
      const i = this.filterTags.findIndex(
        (item) => item.filterKeys[0] === filterKeys[0]
      );
      this.filterTags.splice(i, 1);
      // 对应filterObj也的删除
      filterKeys.forEach((k) => delete this.filterObj[k]);
    },

    // 其他筛选 数据筛选
    filterObjSet({ label, value, filterKeys }) {
      Object.assign(this.filterObj, value);
      // 数据去重
      const i = this.filterTags.findIndex(
        (item) => item.filterKeys[0] === filterKeys[0]
      );
      i === -1
        ? this.filterTags.push({ label, filterKeys })
        : this.filterTags.splice(i, 1, { label, filterKeys });
    },
    // 确定  只针对其他筛选 数据筛选
    submitData() {
      let coopObj = {
        coopBrand: this.coopBrand,
        coopCampaignType: this.coopCampaignType,
      };
      const filter = Object.assign({}, this.filterObj);
      const tags = Object.assign({ filter }, this.tags);
      this.$emit("handleCoop", coopObj);
      this.$emit("handleDataFilterTag", [...this.filterTags]);
      setTimeout(() => {
        this.$emit("handleFilter", tags);
      }, 500);
    },
    // 重置
    resetClick() {
      // tags变化会自动调用submitData
      this.tags = {
        categories: ["All"], // 标签
        tiers: ["All"], // tier，榜单1.0没有tier
        labels: ["All"],
        // filter: {}, // 其他筛选,
      };
      this.coopBrand = "";
      this.coopCampaignType = [];
      this.filterObj = {};
      this.filterTags = [];
      this.resetFilterItem = true;
      setTimeout(() => {
        this.resetFilterItem = false;
      }, 200);
    },
  },
  watch: {
    // 清空数据
    modelValue(newVal) {
      if (newVal) {
        this.resetClick();
        setTimeout(() => {
          this.$emit("update:modelValue", false);
        }, 200);
      }
    },
    platformClassifyData: {
      handler() {
        this.tags.categories = ["All"];
      },
      deep: true,
    },
    tierData: {
      handler() {
        this.tags.tiers = ["All"];
      },
      deep: true,
    },
    labelData: {
      handler() {
        this.tags.labels = ["All"];
      },
      deep: true,
    },
    tags: {
      handler() {
        this.submitData();
      },
      deep: true,
    },
  },
  components: {
    tagItem,
    filterItem,
  },
};
</script>

<style scoped lang="scss">
.tagShowDiv {
  border: 1px solid #dcdfe6;
  padding: 0 15px 5px;
}

.title {
  border-bottom: 1px solid #dcdfe6;
  margin: 12px 0 10px;
  font-weight: bold;
  height: 19px;
  font-size: 14px;
}
</style>
