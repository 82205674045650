<template>
  <div
      style="
      width: 480px;
      display: inline-block;
      margin-right: 50px;
      margin-bottom: 10px;
      font-size: 14px;
    "
  >
    <div style="display: flex; align-items: center">
      <span style="margin-right: 10px; color: #888; width: 130px">
        {{ data.label }}
      </span>
      <div class="flex_between">
        <div class="flex_between">
          <el-input-number
              :controls="false"
              v-model="numMin"
              @change="changeValueMin"
              :min="0"
              :max="numMax"
          ></el-input-number>
          <span v-if="data.showPercent"> %</span>
        </div>
        <span>&nbsp;-&nbsp;</span>
        <div class="flex_between">
          <el-input-number
              :controls="false"
              v-model="numMax"
              @change="changeValueMax"
              :min="numMin"
              :max="data.showPercent === true ? 100 : 1000000000"
          ></el-input-number>
          <span v-if="data.showPercent"> %</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "filterItem",
  data() {
    return {
      numMin: undefined,
      numMax: undefined,
    };
  },
  props: {
    data: {
      type: Object,
      default() {
        return {
          label: "",
          value: "",
          showPercent: "",
          isDeleteData: false,
        };
      },
    },
    clearOneData: {
      type: String,
      default: "",
    },
    resetFlag: {
      type: Boolean,
      default: false,
    },
    clearInput: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    changeValueMin(v){
      if(Number(v) > 100000000){
        this.$message.error("输入值过大");
        this.numMin = undefined
        return;
      }
      this.changeValue(v);
    },
    changeValueMax(v){
      // if(Number(v) > 100000000){
      //   this.$message.error("输入值过大");
      //   this.numMax = undefined
      //   return;
      // }
      this.changeValue(v);
    },
    changeValue() {
      const aboveKey = this.data.value + "Below";
      const aboveValue =
          this.numMin == undefined
              ? "0"
              : this.data.showPercent
                  ? this.numMin / 100
                  : this.numMin;
      const belowKey = this.data.value + "Above";
      const belowValue =
          this.numMax == undefined
              ? "999999999"
              : this.data.showPercent
                  ? this.numMax / 100
                  : this.numMax;
      const value = {
        [aboveKey]: aboveValue,
        [belowKey]: belowValue,
      };
      const label =
          aboveValue === "0"
              ? `${this.data.label} ≤ ${belowValue}`
              : belowValue === "999999999"
                  ? `${this.data.label} ≥ ${aboveValue}`
                  : `${aboveValue} ≤ ${this.data.label} ≤ ${belowValue}`;
      // 向上传递筛选展示的tag
      if (aboveValue === "0" && belowValue === "999999999") {
        this.$emit("deleteFilter", [aboveKey, belowKey]);
      } else {
        this.$emit("handleValue", {
          label,
          value,
          filterKeys: [aboveKey, belowKey],
        });
      }
    },
  },
  watch: {
    data: {
      handler() {
        this.numMin = undefined;
        this.numMax = undefined;
      },
      deep: true,
    },
    resetFlag(newVal) {
      if (newVal) {
        this.numMin = undefined;
        this.numMax = undefined;
      }
    },
    clearOneData: {
      handler(key) {
        console.log(key, this.data.value)
        if (this.data.value === key.split("-")[0]) {
          console.log(this.data.value)
          this.numMin = undefined;
          this.numMax = undefined;
        }
      },
      deep: true,
    },
  },
};
</script>

<style scoped lang="scss"></style>
